import React from 'react';
import CloseIcon from '$icons/close.svg';
import { Button } from '~/shared/components';
import { useTranslation } from '~/shared/utils';
import {
    StyledSummaryDivider,
    StyledSummary,
    StyledSummaryText,
    StyledVoucherContainer,
} from './styled';
import { useCheckoutFeatures } from '~/features/checkout/hooks/useCheckoutFeatures';
import { useVoucherRemove } from '~/features/basket/hooks/useVoucherRemove';
import { BasketViewModel, OrderReceiptViewModel } from '~/lib/data-contract';
import { SummaryTableRow } from './components/SummaryTableRow';
import { useGiftcard } from '~/features/basket/hooks';

type Props = {
    sum: BasketViewModel | OrderReceiptViewModel;
    editable?: boolean;
    onlyShowTotal?: boolean;
    variant?: 'standard' | 'minimal';
};

export const Summary = ({ editable = true, onlyShowTotal = false, sum, variant }: Props) => {
    const { remove } = useVoucherRemove();
    const { remove: removeGiftcard } = useGiftcard();
    const { translate } = useTranslation();
    const fontVariant = variant === 'minimal' ? 'caption' : 'bodySm';
    const { userExistsInDifferentMarket } = useCheckoutFeatures();
    const rowFontVariant = variant === 'minimal' ? 'caption' : 'body';

    const bonusDisplayPrice = sum?.bonusViewModel?.bonusAppliedDisplayPrice || '';

    const bonusEarnedDisplayPrice =
        (sum.bonusViewModel?.userExists && sum.bonusViewModel?.bonusEarnedDisplayPrice) || '';

    if (variant === 'minimal') {
        return (
            <StyledSummary variant={fontVariant}>
                <tbody>
                    <SummaryTableRow
                        children={translate('price.delivery')}
                        value={sum?.displayShippingPrice}
                    />
                    <SummaryTableRow
                        children={
                            <StyledSummaryText
                                as="b"
                                variant={rowFontVariant}
                                children={translate('price.priceWithTax')}
                            />
                        }
                        value={
                            <StyledSummaryText
                                as="b"
                                variant={rowFontVariant}
                                children={sum?.displayTotalPriceInclTax}
                            />
                        }
                    />
                </tbody>
            </StyledSummary>
        );
    }

    return (
        <StyledSummary variant={fontVariant}>
            <tbody>
                {!onlyShowTotal && (
                    <>
                        <SummaryTableRow
                            children={translate('price.total')}
                            value={sum?.displayTotalPriceBeforeDiscount}
                        />
                        <SummaryTableRow
                            children={translate('price.discount')}
                            value={sum?.displayDiscountPriceForLineItems}
                            type="discount"
                        />
                        <SummaryTableRow
                            children={translate('price.bonus')}
                            value={bonusDisplayPrice}
                            type="discount"
                        />
                        <SummaryTableRow
                            children={translate('price.delivery')}
                            value={sum?.displayShippingPrice}
                        />

                        {sum?.vouchers?.map(({ name, discountedValue, id: voucherId }) => (
                            <SummaryTableRow
                                key={voucherId}
                                type="discount"
                                children={
                                    <StyledVoucherContainer>
                                        {editable && (
                                            <Button
                                                onClick={() => voucherId && remove({ voucherId })}
                                                children={<CloseIcon />}
                                                shape="icon"
                                                size="sm"
                                                variant="tertiary"
                                                showHoverIndicator={false}
                                                type="button"
                                            />
                                        )}
                                        {name}
                                    </StyledVoucherContainer>
                                }
                                value={discountedValue}
                            />
                        ))}
                        {sum?.giftCards?.map(({ certificateId = '', displayGiftCardPrice }) => (
                            <SummaryTableRow
                                key={certificateId}
                                type="discount"
                                children={
                                    <StyledVoucherContainer>
                                        {editable && (
                                            <Button
                                                onClick={() =>
                                                    certificateId &&
                                                    removeGiftcard({ certificateId })
                                                }
                                                children={<CloseIcon />}
                                                shape="icon"
                                                size="sm"
                                                variant="tertiary"
                                                showHoverIndicator={false}
                                                type="button"
                                            />
                                        )}
                                        {translate('price.giftcard', { certificateId })}
                                    </StyledVoucherContainer>
                                }
                                value={displayGiftCardPrice}
                            />
                        ))}

                        <tr>
                            <td colSpan={2}>
                                <StyledSummaryDivider />
                            </td>
                        </tr>
                    </>
                )}

                <SummaryTableRow
                    children={
                        <StyledSummaryText
                            as="b"
                            variant={rowFontVariant}
                            children={translate('price.priceWithTax')}
                        />
                    }
                    value={
                        <StyledSummaryText
                            as="b"
                            variant={rowFontVariant}
                            children={sum?.displayTotalPriceInclTax}
                        />
                    }
                />
                {!userExistsInDifferentMarket && (
                    <SummaryTableRow
                        children={
                            <StyledSummaryText
                                variant="caption"
                                children={translate('price.bonusEarned')}
                            />
                        }
                        value={
                            bonusEarnedDisplayPrice ? (
                                <StyledSummaryText
                                    variant="caption"
                                    children={bonusEarnedDisplayPrice}
                                />
                            ) : (
                                ''
                            )
                        }
                        type="earning"
                    />
                )}
            </tbody>
        </StyledSummary>
    );
};
