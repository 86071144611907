import { useBasket } from '~/features/basket';
import { useClub } from '~/features/club';
import { useFrame } from '~/shared/utils';

export const useCheckoutFeatures = () => {
    const { isAuthenticating } = useClub();
    const { data: basket } = useBasket();
    const { data: frame } = useFrame();
    const { bonusPayment, bonusSummary, giftcardPayment, signUp } = frame?.settings?.features || {};

    const userEmail = basket?.deliveryInformationViewModel?.mail;
    const onlyGiftCardsInBasket = basket?.isOnlyGiftCardsInBasket;
    const isBonusZero = basket?.bonusViewModel?.isBonusZero;

    const userExists = Boolean(basket?.bonusViewModel?.userExists);
    const userExistsInDifferentMarket = Boolean(basket?.bonusViewModel?.userExistsInDifferentMarket);
    const showToggleBonus = 
    !userExistsInDifferentMarket &&
     bonusPayment && userEmail && userExists && !(onlyGiftCardsInBasket || isBonusZero);

    const showSignup = signUp && !isAuthenticating && !userExists && userEmail;
    const showSummary = bonusSummary && userExists && !onlyGiftCardsInBasket;
    const showVoucherGiftcardField = !onlyGiftCardsInBasket;
    const showSignupEarningsText = !onlyGiftCardsInBasket;

    return {
        showToggleBonus,
        showSignup,
        showSummary,
        showGiftcardPayment: giftcardPayment,
        showVoucherGiftcardField,
        showSignupEarningsText,
        userExistsInDifferentMarket
    };
};
